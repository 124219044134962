import type { OrderItem } from '@/features/orders/types';
import { OrderItemStatus } from '@/features/orders/types';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { AgeVerificationDTO } from '../types';
import { deepClone } from '@/utils/helpers/deepClone';
import { useHandoverOrder } from '@/features/handover/composables';

/**
 * Set status for age restricted staged items to rejected.
 */

export class SetItemRejectionStatusPlugin
  implements PipelinePlugin<AgeVerificationDTO>
{
  public execute(dto: AgeVerificationDTO): AgeVerificationDTO {
    // FIXME: Do not use Composables in Plugins. If the functionality is needed here, outsource the functionality form the composable
    const { buildNewRejectedItem, handleStagedItemRejection } =
      useHandoverOrder();
    dto.order.items.forEach((item: OrderItem) => {
      if (
        item.product.ageRestricted > 0 &&
        item.status === OrderItemStatus.staged
      ) {
        const newRejectedItem = buildNewRejectedItem(
          item,
          item.quantity,
          item.amount,
          true,
        );

        dto.order.items.push(newRejectedItem);

        handleStagedItemRejection(item, item.quantity, item.amount);
      }
    });
    return dto;
  }

  // FIXME: Remove if not needed
  private getRejectedItem(stagedItem: OrderItem): OrderItem {
    const newRejectedItem = deepClone(stagedItem);
    newRejectedItem.status = OrderItemStatus.rejected;
    newRejectedItem.isRejected = true;

    return newRejectedItem;
  }
}
