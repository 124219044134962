import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { AgeVerificationOnHandoverDTO } from '../types';

/**
 * Set redirect path of age verification
 */

export class SetRedirectPath
  implements PipelinePlugin<AgeVerificationOnHandoverDTO>
{
  public execute(
    dto: AgeVerificationOnHandoverDTO,
  ): AgeVerificationOnHandoverDTO {
    dto.redirect = `/age-verification/${String(dto.redirectTo.params.id)}`;

    return dto;
  }
}
