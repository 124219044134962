
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    legend: String,
    dataE2e: {
      type: String,
      default: 'form-group',
    },
  },
});
