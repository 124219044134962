<template>
  <fieldset class="group">
    <legend v-if="legend" :data-e2e="`${dataE2e}-label`" class="legend">
      {{ legend }}
    </legend>
    <slot></slot>
  </fieldset>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    legend: String,
    dataE2e: {
      type: String,
      default: 'form-group',
    },
  },
});
</script>

<style lang="scss" scoped>
.group {
  padding: 0;
  margin: 0;
  font-size: 14px;
  text-align: left;
  border: none;
  line-height: 1.14em;
}

.legend {
  padding-bottom: 8px;
  width: 100%;
  border-bottom: 1px solid #b6c4df;
}
</style>
